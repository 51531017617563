.header {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionsWrapper {
  svg {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
}

.timerWrapper {
  min-width: 105px;
  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
  &:last-child{
    text-align: right;
  }
}
