
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 100px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}
.ticketName{
  padding: 5px;
  font-weight: bold;
  text-decoration: underline;
  display: flex;
  align-items: center;
}

.mediaText {
  min-width: 500px;
  max-width: 500px;
  max-height: 500px;
  padding: 10px;
  text-align: center;

  & img {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: 600px) {
    min-width: 100%;
  }
}

.answerItem {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #fafafa;

  @media screen and (min-width: 600px) {
    &:hover {
      background-color: #fafafa;
    }
  }


  &__success {
    background-color: greenyellow;
  }

  &__failed {
    background-color: pink;
  }
}

.prompt {
  color: gray;
  margin-top: 50px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 30px;
  @media screen and (max-width: 600px) {
    position: fixed;
    bottom: 0;
    line-height: 25px;
    text-align: center;
    width: 100%;
    margin: 0;
    left: 0;
    background-color: lightgray;
  }
}

.promptText {
  padding: 10px;
  color: gray;
}

.answersWrapper {
  outline: 1px solid gainsboro;
  margin: 5px;
  width: 100%;
  margin-bottom: 40px;
  &__reply{
    margin-bottom: 0;
  }
}

.text {
  font-weight: bold;
  padding: 5px;
  text-align: justify;
  //min-height: 100px;
  max-width: 500px;
  margin: 0 auto;
}


.container {
  //text-align: center;
}
