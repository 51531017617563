.blockItem {
  min-width: 50px;
  height: 50px;
  margin-left: 5px;
  border: 1px solid wheat;
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  color: gray;

  &__current {
    border-color: red;
  }

  &__success {
    background-color: greenyellow;
  }

  &__failed {
    background-color: pink;
  }
}

.questionsContainer {
  //max-width: 900px;
  margin: 0 auto;
}

.headerContainer {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: auto;
  justify-content: space-between;
}
